import { CountryCode, ExternalTool, RemoteConfigParameter, ReportConfigurationSection } from '@nx-customer-apps/shared/enums';

export const environment = {
    production: false,
    validateAccess: false,
    applicationName: 'ViGuide Planning',
    baseUrl: 'https://viguide-planning-staging.viessmann.com',
    baseApiUrl: 'https://api-staging.viessmann.com',
    environmentHeaders: {},
    externalTools: {
        [ExternalTool.HeatPumpPlanner]: 'https://heatpump-planner-staging.viessmann.com/',
        [ExternalTool.PhotovoltaicPlanner]: 'https://pv-planner-staging.viessmann.com/',
        [ExternalTool.QuoteAssist]: 'https://qa-staging.viessmann.com',
        [ExternalTool.PartnerShop]: 'https://shop-stage.viessmann.com'
    },
    serviceWorker: true,
    environment: 'staging',
    hotJarEnabled: true,
    partnerPortalBaseUrl: 'https://partnerportal-staging.viessmann.com',
    everestApiUrl: 'https://api-staging.viessmann.com',
    accountApiUrl: 'https://account-staging.viessmann.com',
    registerRedirectUrl: 'https://viguide-planning-staging.viessmann.com',
    localeConfig: {
        localStorageKey: 'locale'
    },
    storageKeys: {
        locale: 'locale',
        nextRoute: 'nextRoute',
        lockProjectId: 'lockProjectId',
        onboarding: 'onboarding'
    },
    projectLockConfig: {
        nextAttemptLockDelay: 5000, // 5 seconds
        verifyLockPollingInterval: 1000 * 60 * 10, // 1 minute,
        disabled: true
    },
    csrfTokenConfig: {
        appId: 'viguide-planning-projects',
        localStorageKey: 'csrf_token',
        apiUrl: 'https://api-staging.viessmann.com/auth/v1/saml/csrf'
    },
    meUrl: 'https://api-staging.viessmann.com/users/v1/users/me',
    samlConfig: {
        requestLoginUrl: 'https://api-staging.viessmann.com/saml/sso/request'
    },
    globalHeader: {
        iamBackgroundImage: 'iam_lpi_000001.png'
    },
    remoteConfig: {
        remoteConfigUrl: 'https://api-staging.viessmann.com/remote-config/v3/fetch',
        appId: '9ab69877-3952-4f13-a6f8-27435c7b6523',
        enabled: true,
        defaultConfig: {
            [RemoteConfigParameter.CreatePdfReportButtonVisible]: false,
            [RemoteConfigParameter.CountryDropdownByCountry]: `${CountryCode.DE},${CountryCode.NL},${CountryCode.AT},${CountryCode.BE}`,
            [RemoteConfigParameter.CountryLanguageSwitcherByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.BE}`,
            [RemoteConfigParameter.PdfConfiguratorBySection]: `${ReportConfigurationSection.GeneralInformation},${ReportConfigurationSection.HeatPumpPlan},${ReportConfigurationSection.PVPlan},${ReportConfigurationSection.VentilationPlan},${ReportConfigurationSection.FullSystem},${ReportConfigurationSection.LegalNotice}`,
            [RemoteConfigParameter.PdfConfiguratorGeneralInformationBySubsection]: `${ReportConfigurationSection.GeneralProjectData},${ReportConfigurationSection.CurrentSystem},${ReportConfigurationSection.PlannedSystem},${ReportConfigurationSection.EnergyPrices},${ReportConfigurationSection.HeatLoadCalculations},${ReportConfigurationSection.GEGRecommendations}`,
            [RemoteConfigParameter.PdfConfiguratorHeatPumpPlanBySubsection]: `${ReportConfigurationSection.HPSimulationResults},${ReportConfigurationSection.HPFinancialPerformance},${ReportConfigurationSection.AppendixJAZ},${ReportConfigurationSection.HPBillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorPhotovoltaicPlanBySubsection]: `${ReportConfigurationSection.Roofs},${ReportConfigurationSection.PVTechnicalDetails},${ReportConfigurationSection.PVSimulationResults},${ReportConfigurationSection.PVInstallationPlans},${ReportConfigurationSection.PVStaticsAnalysisReport},${ReportConfigurationSection.PVFinancialPerformance},${ReportConfigurationSection.PVBillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorVentilationPlanBySubsection]: ',',
            [RemoteConfigParameter.PdfConfiguratorFullSystemBySubsection]: `${ReportConfigurationSection.FullSystemSimulation},${ReportConfigurationSection.FinancialPerformance},${ReportConfigurationSection.BillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorLegalNoticeBySubsection]: ',',
            [RemoteConfigParameter.DomesticElectricityDayNightTariffByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.BE},${CountryCode.CH},${CountryCode.PL},${CountryCode.FR}`,
            [RemoteConfigParameter.HeatPumpElectricityFlatRateByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.PL}`,
            [RemoteConfigParameter.HeatPumpElectricityDayNightTariffByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.PL}`,
            [RemoteConfigParameter.HeatPumpElectricityBlockTimeByCountry]: `${CountryCode.AT},${CountryCode.CH}`,
            [RemoteConfigParameter.OnboardingVersion]: '2.22.0'
        }
    },
    statusPageUrl: 'https://api-staging.viessmann.com/status/v1/apps/lx423xlz93yc/summary',
    servicePageUrl: 'https://viessmann.statuspage.io'
};
